import { Link } from "gatsby";
import React, { useState } from "react";
// import { isLoggedIn, logout } from "../helpers/user";

function Header() {
    const [isExpanded, toggleExpansion] = useState(false);

    return (
        <header className="w-auto md:min-h-screen md:flex">

            <div className="bg-tertiary text-gray-100 flex justify-between md:hidden">

                <Link to="/">
                    <a className="block p-4 text-white">WeStash</a>
                </Link>

                <button className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700" onClick={() => toggleExpansion(!isExpanded)}>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>

            <div className={`${isExpanded ? `` : `-translate-x-full`} z-[100] sidebar bg-tertiary text-blue-100 w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out`}>

                <Link to="/">
                    <a href="#" className="text-white flex items-center space-x-2 px-4">
                        <span className="text-2xl">WeStash</span>
                    </a>
                </Link>

                <nav>
                    <Link to="/app/home">
                        <a className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white">
                            Home
                        </a>
                    </Link>

                    <Link to="/app/files">
                        <a className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white">
                            Recent uploads
                        </a>
                    </Link>

                    <a href="" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white">
                        Account
                    </a>

                    <Link to="/app/files">
                        <a className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white">
                            Logout
                        </a>
                    </Link>
                </nav>
            </div>

        </header>
    );
}

export default Header;

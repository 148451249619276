import React from "react";

import { useLocation } from '@reach/router';
import PropTypes from "prop-types";
import Header from "./header";
import SideHeader from "./sideHeader";

function Layout({ children }) {

  const location = useLocation();

  if (location.pathname.includes('/app')) {
    return (
      <div className="flex flex-col min-h-screen font-robMono text-secondary bg-primary md:flex-row">
        <SideHeader />
        {children}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col min-h-screen font-robMono text-secondary bg-primary">
        <Header />
        <main className="">
          {children}
        </main>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import { isLoggedIn, logout } from "../helpers/user";

function Header() {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-tertiary h-[8vh]">
      <div className="flex flex-wrap items-center justify-between max-w-7xl p-4 mx-auto">
        <Link to="/">
          <span className="text-xl font-bold tracking-tight">
            {site.siteMetadata.title}
          </span>
        </Link>

        <nav>
          {
            isLoggedIn() ?
              <Link to={"/"}>
                <button className="bg-transparent hover:border-gold hover:text-gold font-semibold py-2 px-4 border border-secondary rounded" onClick={logout}>
                  Logout
                </button>
              </Link> :
              <Link to={"/login"}>
                <button className="bg-transparent hover:border-gold hover:text-gold font-semibold py-2 px-4 border border-secondary rounded">
                  Login
                </button>
              </Link>
          }
        </nav>
      </div>
    </header>
  );
}

export default Header;

import { createClient } from '@supabase/supabase-js'

const supabaseUrl = "https://kshmhxasvyentsbgtvpl.supabase.co"
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzNjk5NDI1NSwiZXhwIjoxOTUyNTcwMjU1fQ.QMPnw20l6S719EmkLvXxMwQ4tplBgZP3nnQfi5sP4rk"

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export async function signUp(params) {
    try {
        const { user, error } = await supabase.auth.signUp({
            email: params.email,
            password: params.password1,
        })
        if (error) throw error
        return user
    } catch (error) {
        alert(error.error_description || error.message)
    }
}

export async function signIn(params) {
    try {
        const { user, error } = await supabase.auth.signIn({
            email: params.email,
            password: params.password,
        })
        if (error) throw error
        return { user }
    } catch (error) {
        alert(error.error_description || error.message)
    }
}
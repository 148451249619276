
import { signIn, signUp } from "./authentication";

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>{
    if (isBrowser()) {
        const user = window.localStorage.getItem("WeStashUser")
        //check if user is not null
        if (user) {
            try {
                return JSON.parse(user);
            } catch (err) {
                console.log(JSON.parse(err.message).foo);
            }
            
        }
    } 
}

const setUser = user =>
    window.localStorage.setItem("WeStashUser", JSON.stringify(user))

export async function registerUser(params) {
    return await signUp(params);
}

export async function loginUser(params) {
    return await signIn(params).then(user => {
        if (user) {
            setUser(user)
        }
    });
}

export const isLoggedIn = () => {
    const user = getUser()
    return user ? true : false
}

export const logout = () => {
    setUser(null)
}